/* Packages */
import React, { useContext, useEffect, useState } from 'react'
import BackgroundImage from 'gatsby-background-image'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './verticalImages.module.scss'
/* Component */
const VerticalImages = () => {
  const { windowSize: { device }, homeImages } = useContext(Context)

  const HomeImage = ({ i, homeImage: { title, text, image, mobileImage } }) => {
    const [verticalImagesClasses, setVerticalImagesClasses] = useState(styles.verticalImage)
    const [coverClasses, setCoverClasses] = useState(styles.cover)

    useEffect(() => {
      setVerticalImagesClasses(styles.verticalImage + `${device === 'phone' ? ' ' + styles.mobile : ''}`)
      setCoverClasses(styles.cover)
      setTimeout(() => {
        setVerticalImagesClasses(styles.verticalImage + ' ' + styles.show + `${device === 'phone' ? ' ' + styles.mobile : ''}`)
        setTimeout(() => {
          setCoverClasses(styles.cover + ' ' + styles.hide)
        }, ((i + 1) * 100) + 500)
      }, (i + 1) * 100)
    }, [homeImages])

    return (
      <div className={verticalImagesClasses}>
        <BackgroundImage Tag='div' className={styles.image} 
          fluid={device === 'phone' ? mobileImage.fluid : image.fluid}>
          <div className={styles.homeCopy}>
            <p className={styles.copyContainer}>
              <span className={styles.title}>{title.toUpperCase()} //</span>
              <span className={styles.copy}>{text}</span>
            </p>
          </div>
          <div className={coverClasses} />
        </BackgroundImage>
      </div>
    )
  }
  return (
    <section className={styles.verticalImages}>
      {homeImages.map((homeImage, i) => <HomeImage key={i} i={i} homeImage={homeImage} />)}
    </section>
  )
}
/* Exports */
export default VerticalImages

/* Packages */
import React, { useContext, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './featuredLogo.module.scss'
/* Component */
const FeaturedLogo = () => {
  const { homeFeaturedLogo, homePageLogoStatus } = useContext(Context)

  useEffect(() => {
    if (homeFeaturedLogo && homePageLogoStatus) {
      setTimeout(() => {
        document.getElementsByClassName(styles.featuredLogoContainer)[0].classList.add(styles.show)
      }, 1000)
    }
  }, [homeFeaturedLogo])

  return (
    homeFeaturedLogo && homePageLogoStatus ? (
      <div className={styles.featuredLogoContainer}>
        <img className={styles.featuredLogo} src={homeFeaturedLogo} alt='T.D. Williamson 100 Years 1920-2020' />
      </div>
    ) : (
      <span />
    )
  )
}
/* Exports */
export default FeaturedLogo

/* Packages */
import React from 'react'
/* Imported Components */
import { Layout } from '../components'
import VerticalImages from '../components/home/verticalImages/verticalImages'
import FeaturedLogo from '../components/home/featuredLogo/featuredLogo'
import FeaturedText from '../components/home/featuredText/featuredText'
/* Styles */
import styles from './index.module.scss'
/* Component */
const IndexPage = () => {
  return (
    <Layout title='Home'>
      <section className={styles.section}>
        <VerticalImages />
        <FeaturedLogo />
        <FeaturedText />
      </section>
    </Layout>
  )
}
/* Exports */
export default IndexPage

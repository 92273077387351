/* Packages */
import React, { useContext, useEffect } from 'react'
/* Imported Components */
import { Context } from '../../../context'
/* Styles */
import styles from './featuredText.module.scss'
/* Component */
const FeaturedText = () => {
  const { homeFeaturedText } = useContext(Context)

  useEffect(() => {
    if (homeFeaturedText) {
      setTimeout(() => {
        document.getElementsByClassName(styles.featuredTextContainer)[0].classList.add(styles.show)
      }, 1500)
    }
  }, [homeFeaturedText])

  return (
    <div className={styles.featuredTextContainer}>
      <p><strong>{homeFeaturedText.toUpperCase()}</strong></p>
    </div>
  )
}
/* Exports */
export default FeaturedText
